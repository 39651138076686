<!--
 * @Author: gaojingran
 * @Date: 2021-03-20 13:42:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-12 16:33:24
 * @Description: 系统风格指南
-->

<style lang="less" scoped>
@import './index.less';
</style>

<template>
  <div class="pt-4 pb-4 pl-4 pr-4">
    <a-card title="Button">
      <a-space :size="10">
        <a-button type="primary"> Primary </a-button>
        <a-button>Default</a-button>
        <a-button type="dashed"> Dashed </a-button>
        <a-button type="dashed" disabled> Disabled </a-button>
        <a-button type="danger"> Danger </a-button>
        <a-button type="primary"> 按钮 </a-button>
        <a-button type="link"> Link </a-button>

        <a-button type="link"> 下载文档 <a-icon type="download" /> </a-button>

        <a-button class="neo-btn-brand"> Neo Button hover 渐变 </a-button>
        <a-button class="neo-btn-primary"> Neo Button </a-button>

        <a-button type="primary" @click="$i18n.locale = 'zh-CN'"> 简体中文 </a-button>
        <a-button type="primary" @click="$i18n.locale = 'en-US'"> English </a-button>
      </a-space>
    </a-card>

    <a-card class="mt-3" title="delete">
      <a-popconfirm
        :title="$t('prompt.info', { handle: $t('delete') })"
        :ok-text="$t('confirm')"
        :cancel-text="$t('cancel')"
        @confirm="() => {}"
        @cancel="() => {}"
      >
        <a href="#">Delete</a>
      </a-popconfirm>
    </a-card>

    <a-card class="mt-3" title="NeoBreadcrumb">
      <NeoBreadcrumb :options="dateOptions" v-model="dataValue" />
    </a-card>

    <a-card class="mt-3" title="font size">
      <a-space :size="10">
        <p class="fs-1">font size 12</p>
        <p class="fs-2">font size 13</p>
        <p class="fs-3">font size 14</p>
        <p class="fs-4">font size 16</p>
        <p class="fs-5">font size 18</p>
        <p class="fs-6">font size 24</p>
      </a-space>
    </a-card>

    <a-card class="mt-3" title="font color">
      <a-space :size="10">
        <p class="c-1">color 1</p>
        <p class="c-2">color 2</p>
        <p class="c-3">color 3</p>
        <p class="c-4">color 4</p>
        <p class="c-5">color 5</p>
        <p class="primary">font Color</p>
        <p class="error">font Color</p>
        <p class="warning">font Color</p>
        <p class="success">font Color</p>
      </a-space>
    </a-card>

    <a-card class="mt-3" title="background color">
      <a-space :size="10">
        <p class="bg-1 pt-2 pb-2 pl-2 pr-2">background 1</p>
        <p class="bg-2 pt-2 pb-2 pl-2 pr-2">background 2</p>
        <p class="bg-3 pt-2 pb-2 pl-2 pr-2">background 3</p>
        <p class="bg-4 pt-2 pb-2 pl-2 pr-2">background 4</p>
        <p class="bg-5 pt-2 pb-2 pl-2 pr-2">background 5</p>
        <p class="bg-6 pt-2 pb-2 pl-2 pr-2">background 6</p>
        <p class="bg-7 pt-2 pb-2 pl-2 pr-2">background 7</p>
      </a-space>
    </a-card>

    <a-card class="mt-3" title="NeoTitle">
      <a-space :size="10" direction="vertical">
        <NeoTitle text="基本信息" />
        <NeoTitle text="生产对接" />
        <NeoTitle text="报价配置" />
      </a-space>
    </a-card>

    <a-card class="mt-3" title="NeoTag">
      <a-space :size="10">
        <NeoTag>正常</NeoTag>
        <NeoTag type="warning">正常</NeoTag>
        <NeoTag type="danger">正常</NeoTag>
        <NeoTag type="success">正常</NeoTag>
        <NeoTag type="success" size="mini">28</NeoTag>

        <NeoTag shape>正常</NeoTag>
        <NeoTag shape type="warning">正常</NeoTag>
        <NeoTag shape type="warning" size="mini">28</NeoTag>
        <NeoTag shape type="danger">正常</NeoTag>
        <NeoTag shape type="success">正常</NeoTag>
      </a-space>
    </a-card>

    <a-card class="mt-3" title="NeoTips">
      <NeoTips label="注" text="文件翻译目前只提供人工报价" />
    </a-card>

    <a-card class="mt-3" title="NeoIcon">
      <NeoIcon name="logo" :size="200" />
      <NeoIcon name="logo-color" :size="200" />
      <NeoIcon class="error" name="fire" :size="20" />
      <NeoIcon class="primary" name="fire" :size="20" />
    </a-card>

    <a-card class="mt-3" title="NeoUploadDragger">
      <div class="mb-4">
        {{ uploadValue }}
      </div>
      <NeoUploadDragger v-model="uploadValue" :maxLength="5" />
    </a-card>

    <a-card class="mt-3" title="NeoUploadDraggerFile">
      <div class="mb-4">
        {{ uploadValueB }}
      </div>
      <NeoUploadDragger
        fileType="tag"
        v-model="uploadValueB"
        :defaultCollapsed="true"
        :collapsible="false"
        :maxLength="5"
        :maxSize="10"
      />
    </a-card>

    <a-card class="mt-3" title="NeoUploadButton">
      <div class="mb-4">
        {{ uploadValueC }}
      </div>
      <NeoUploadButton v-model="uploadValueC" :maxLength="5" :maxSize="10" />
    </a-card>

    <a-card class="mt-3" title="NeoScroll">
      <NeoScroll class="scroll-area">
        <div class="bg-3" style="width: 2000px; height: 600px"></div>
      </NeoScroll>
    </a-card>

    <a-card class="mt-3" title="NeoNoScrollWrap">
      <NeoNoScrollWrap style="width: 200px; height: 200px">
        <p style="width: 400px">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus incidunt sequi cupiditate deleniti ad. Odit,
          nostrum nihil, dignissimos reprehenderit perferendis porro repudiandae ratione doloribus inventore tenetur
          animi autem sint temporibus?
        </p>
        <p style="width: 400px">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus incidunt sequi cupiditate deleniti ad. Odit,
          nostrum nihil, dignissimos reprehenderit perferendis porro repudiandae ratione doloribus inventore tenetur
          animi autem sint temporibus?
        </p>
        <p style="width: 400px">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus incidunt sequi cupiditate deleniti ad. Odit,
          nostrum nihil, dignissimos reprehenderit perferendis porro repudiandae ratione doloribus inventore tenetur
          animi autem sint temporibus?
        </p>
        <p style="width: 400px">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus incidunt sequi cupiditate deleniti ad. Odit,
          nostrum nihil, dignissimos reprehenderit perferendis porro repudiandae ratione doloribus inventore tenetur
          animi autem sint temporibus?
        </p>
        <p style="width: 400px">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus incidunt sequi cupiditate deleniti ad. Odit,
          nostrum nihil, dignissimos reprehenderit perferendis porro repudiandae ratione doloribus inventore tenetur
          animi autem sint temporibus?
        </p>
      </NeoNoScrollWrap>
    </a-card>

    <a-card class="mt-3" title="RadioGroup">
      <a-radio-group v-model="radioGroup">
        <a-radio-button value="a">
          Hangzhou
          <NeoTag class="ml-1" type="warning" size="mini">28</NeoTag>
        </a-radio-button>
        <a-radio-button value="b">
          Shanghai
          <NeoTag class="ml-1" type="warning" size="mini">1</NeoTag>
        </a-radio-button>
        <a-radio-button value="c">
          Beijing
          <NeoTag class="ml-1" type="warning" size="mini">108</NeoTag>
        </a-radio-button>
        <a-radio-button value="d">
          Chengdu
          <NeoTag class="ml-1" type="warning" size="mini">8</NeoTag>
        </a-radio-button>
      </a-radio-group>
    </a-card>

    <a-card class="mt-3" title="Empty">
      <a-empty />
    </a-card>

    <a-card class="mt-3" title="Date">
      <p>{{ moment() | dateFormat }}</p>
    </a-card>

    <a-card class="mt-3" title="Notification & Message">
      <a-space :size="10">
        <a-button @click="() => openNotificationWithIcon('success')"> Success </a-button>
        <a-button @click="() => openNotificationWithIcon('info')"> Info </a-button>
        <a-button @click="() => openNotificationWithIcon('warning')"> Warning </a-button>
        <a-button @click="() => openNotificationWithIcon('error')"> Error </a-button>

        <a-button @click="success"> Success Message </a-button>
        <a-button @click="error"> Error Message </a-button>
        <a-button @click="warning"> Warning Message </a-button>
      </a-space>
    </a-card>

    <a-card class="mt-3" title="Form">
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="Fail" validate-status="error" help="Should be combination of numbers & alphabets">
          <a-input id="error" placeholder="unavailable choice" />
        </a-form-item>
        <a-form-item label="Warning" validate-status="warning">
          <a-input id="warning" placeholder="Warning" />
        </a-form-item>
        <a-form-item
          label="Validating"
          has-feedback
          validate-status="validating"
          help="The information is being validated..."
        >
          <a-input id="validating" placeholder="I'm the content is being validated" />
        </a-form-item>
        <a-form-item label="Success" has-feedback validate-status="success">
          <a-input id="success" placeholder="I'm the content" />
        </a-form-item>
        <a-form-item label="Warning" has-feedback validate-status="warning">
          <a-input id="warning2" placeholder="Warning" />
        </a-form-item>
        <a-form-item
          label="Fail"
          has-feedback
          validate-status="error"
          help="Should be combination of numbers & alphabets"
        >
          <a-input id="error2" placeholder="unavailable choice" />
        </a-form-item>
        <a-form-item label="Success" has-feedback validate-status="success">
          <a-date-picker style="width: 100%" />
        </a-form-item>
        <a-form-item label="Warning" has-feedback validate-status="warning">
          <a-time-picker style="width: 100%" />
        </a-form-item>
        <a-form-item label="Error" has-feedback validate-status="error">
          <a-select default-value="1">
            <a-select-option value="1"> Option 1 </a-select-option>
            <a-select-option value="2"> Option 2 </a-select-option>
            <a-select-option value="3"> Option 3 </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="Validating"
          has-feedback
          validate-status="validating"
          help="The information is being validated..."
        >
          <a-cascader :default-value="['1']" :options="[]" />
        </a-form-item>
        <a-form-item label="inline" style="margin-bottom: 0">
          <a-form-item
            validate-status="error"
            help="Please select the correct date"
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
          >
            <a-date-picker style="width: 100%" />
          </a-form-item>
          <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }"> - </span>
          <a-form-item :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
            <a-date-picker style="width: 100%" />
          </a-form-item>
        </a-form-item>
        <a-form-item label="Success" has-feedback validate-status="success">
          <a-input-number style="width: 100%" />
        </a-form-item>
        <a-form-item label="Success" has-feedback validate-status="success">
          <a-input allow-clear placeholder="with allowClear" />
        </a-form-item>

        <a-form-item label="Warning" has-feedback validate-status="warning">
          <a-input-password placeholder="with input password" />
        </a-form-item>

        <a-form-item label="Error" has-feedback validate-status="error">
          <a-input-password allow-clear placeholder="with input password and allowClear" />
        </a-form-item>

        <a-form-item label="aaaaa">
          <a-space :size="10">
            <a-switch />
            <a-rate />
            <a-radio-group :options="plainOptions" />
            <a-checkbox-group name="checkboxgroup" :options="plainOptions" />
          </a-space>
        </a-form-item>

        <a-form-item label="bbbb">
          <a-transfer
            :data-source="mockData"
            show-search
            :filter-option="filterOption"
            :target-keys="targetKeys"
            :render="(item) => item.title"
            @change="handleChange"
            @search="handleSearch"
          />
        </a-form-item>
      </a-form>
    </a-card>

    <a-card class="mt-3" title="Table">
      <a-table
        class="striped head-bg"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :bordered="true"
        size="middle"
        :scroll="{ x: 'calc(700px + 50%)', y: 240 }"
      />
      <a-pagination class="mt-3" show-size-changer :default-current="3" :total="500" />
    </a-card>

    <a-card class="mt-3" title="Table">
      <a-table class="head-bg striped" :pagination="false" :columns="columnsB" :data-source="dataB">
        <template slot="name" slot-scope="text">
          <span>{{ text }}</span>
        </template>
      </a-table>
    </a-card>

    <a-card class="mt-3" title="Table">
      <a-table class="head-bg no-br" :pagination="false" :columns="columnsB" :data-source="dataB" bordered>
        <template slot="name" slot-scope="text">
          <span>{{ text }}</span>
        </template>
        <template slot="footer" slot-scope="currentPageData"> Footer {{ currentPageData }} </template>
      </a-table>
    </a-card>

    <a-card class="mt-3" title="Drawer Modal">
      <a-space :size="10">
        <a-button type="primary" @click="showDrawer"> Open Drawer</a-button>
        <a-button type="primary" @click="modalVisible = true"> Open Modal</a-button>
      </a-space>
      <a-drawer width="50%" title="Basic Drawer" placement="right" :visible="visible" @close="onClose">
        <NeoDrawerLayout :showFooter="true">
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <template v-slot:footer>
            <a-space :size="10">
              <a-button>aaa</a-button>
              <a-button>aaa</a-button>
              <a-button>aaa</a-button>
            </a-space>
          </template>
        </NeoDrawerLayout>
      </a-drawer>
      <a-modal title="Title" :visible="modalVisible" @cancel="modalVisible = false">
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </a-modal>
    </a-card>

    <a-card class="mt-3" title="Tag">
      <a-tag color="pink"> pink </a-tag>
      <a-tag color="red"> red </a-tag>
      <a-tag color="orange"> orange </a-tag>
      <a-tag color="green"> green </a-tag>
      <a-tag color="cyan"> cyan </a-tag>
      <a-tag color="blue"> blue </a-tag>
      <a-tag color="purple"> purple </a-tag>
    </a-card>

    <a-card class="mt-3" title="Alert">
      <a-space :size="10" direction="vertical">
        <a-alert message="Success Tips" type="success" show-icon />
        <a-alert message="Informational Notes" type="info" show-icon />
        <a-alert message="Warning" type="warning" show-icon />
        <a-alert message="Error" type="error" show-icon />
        <a-alert
          message="Success Tips"
          description="Detailed description and advices about successful copywriting."
          type="success"
          show-icon
        />
        <a-alert
          message="Informational Notes"
          description="Additional description and informations about copywriting."
          type="info"
          show-icon
        />
        <a-alert message="Warning" description="This is a warning notice about copywriting." type="warning" show-icon />
        <a-alert message="Error" description="This is an error message about copywriting." type="error" show-icon />
      </a-space>
    </a-card>

    <a-card class="mt-3" title="PageHeader">
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="Title"
        sub-title="This is a subtitle"
        @back="() => $router.go(-1)"
      >
        <template slot="extra">
          <a-space :size="10">
            <a-button key="3"> Operation </a-button>
            <a-button key="2"> Operation </a-button>
            <a-button key="1" type="primary"> Primary </a-button>
          </a-space>
        </template>
        <template slot="footer">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="Details" />
            <a-tab-pane key="2" tab="Rule" />
          </a-tabs>
        </template>
        <div class="content">
          <div class="main">
            <a-descriptions size="small" :column="2">
              <a-descriptions-item label="Created"> Lili Qu </a-descriptions-item>
              <a-descriptions-item label="Association">
                <a>421421</a>
              </a-descriptions-item>
              <a-descriptions-item label="Creation Time"> 2017-01-10 </a-descriptions-item>
              <a-descriptions-item label="Effective Time"> 2017-10-10 </a-descriptions-item>
              <a-descriptions-item label="Remarks">
                Gonghu Road, Xihu District, Hangzhou, Zhejiang, China
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <div class="extra">
            <div
              :style="{
                display: 'flex',
                width: 'max-content',
                justifyContent: 'flex-end',
              }"
            >
              <a-statistic
                title="Status"
                value="Pending"
                :style="{
                  marginRight: '32px',
                }"
              />
              <a-statistic title="Price" prefix="$" :value="568.08" />
            </div>
          </div>
        </div>
      </a-page-header>
    </a-card>

    <a-card class="mt-3" title="Tabs">
      <a-radio-group v-model="mode" class="mb-2">
        <a-radio-button value="top"> Horizontal </a-radio-button>
        <a-radio-button value="left"> Vertical </a-radio-button>
      </a-radio-group>
      <a-tabs default-active-key="1" :tab-position="mode" :style="{ height: '200px' }">
        <a-tab-pane v-for="i in 30" :key="i" :tab="`Tab-${i}`"> Content of tab {{ i }} </a-tab-pane>
      </a-tabs>

      <div :style="{ marginBottom: '15px' }">
        <a-button @click="add"> ADD </a-button>
      </div>
      <a-tabs v-model="activeKey" hide-add type="editable-card" @edit="onEdit">
        <a-tab-pane v-for="pane in panes" :key="pane.key" :tab="pane.title" :closable="pane.closable">
          {{ pane.content }}
        </a-tab-pane>
      </a-tabs>
    </a-card>

    <a-card class="mt-3" title="Comment">
      <a-list :header="`${comment.length} replies`" item-layout="horizontal" :data-source="comment">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-comment :author="item.author" :avatar="item.avatar">
            <template slot="actions">
              <span v-for="action in item.actions" :key="action">{{ action }}</span>
            </template>
            <p slot="content">
              {{ item.content }}
            </p>
            <a-tooltip slot="datetime" :title="item.datetime.format('YYYY-MM-DD HH:mm:ss')">
              <span>{{ item.datetime.fromNow() }}</span>
            </a-tooltip>
          </a-comment>
        </a-list-item>
      </a-list>
    </a-card>

    <a-card class="mt-3" title="Progress">
      <a-progress class="mb-3" :percent="30" />
      <a-progress class="mb-3" :percent="50" status="active" />
      <a-progress class="mb-3" :percent="70" status="exception" />
      <a-progress class="mb-3" :percent="100" />
      <a-progress class="mb-3" :percent="50" :show-info="false" />
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import NeoTips from '@/components/common/NeoTips'
import NeoTag from '@/components/common/NeoTag'
import NeoNoScrollWrap from '@/components/common/NeoNoScrollWrap'
import NeoUploadDragger from '@/components/upload/NeoUploadDragger'
import NeoUploadButton from '@/components/upload/NeoUploadButton'
import NeoBreadcrumb from '@/components/common/NeoBreadcrumb'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 100,
    fixed: 'left',
    filters: [
      {
        text: 'Joe',
        value: 'Joe',
      },
      {
        text: 'John',
        value: 'John',
      },
    ],
    onFilter: (value, record) => record.name.indexOf(value) === 0,
  },
  {
    title: 'Other',
    children: [
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
        width: 200,
        sorter: (a, b) => a.age - b.age,
      },
      {
        title: 'Address',
        children: [
          {
            title: 'Street',
            dataIndex: 'street',
            key: 'street',
            width: 200,
          },
          {
            title: 'Block',
            children: [
              {
                title: 'Building',
                dataIndex: 'building',
                key: 'building',
                width: 100,
              },
              {
                title: 'Door No.',
                dataIndex: 'number',
                key: 'number',
                width: 100,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Company',
    children: [
      {
        title: 'Company Address',
        dataIndex: 'companyAddress',
        key: 'companyAddress',
        width: 200,
      },
      {
        title: 'Company Name',
        dataIndex: 'companyName',
        key: 'companyName',
      },
    ],
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
    width: 80,
    fixed: 'right',
  },
]
const data = []
for (let i = 0; i < 20; i++) {
  data.push({
    key: i,
    name: 'John Brown',
    age: i + 1,
    street: 'Lake Park',
    building: 'C',
    number: 2035,
    companyAddress: 'Lake Street 42',
    companyName: 'SoftLake Co',
    gender: 'M',
  })
}

const columnsB = [
  {
    title: 'Name',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Cash Assets',
    className: 'column-money',
    dataIndex: 'money',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
]

const dataB = [
  {
    key: '1',
    name: 'John Brown',
    money: '￥300,000.00',
    address: 'New York No. 1 Lake Park',
  },
  {
    key: '2',
    name: 'Jim Green',
    money: '￥1,256,000.00',
    address: 'London No. 1 Lake Park',
  },
  {
    key: '3',
    name: 'Joe Black',
    money: '￥120,000.00',
    address: 'Sidney No. 1 Lake Park',
  },
]

const panes = [
  { title: 'Tab 1', content: 'Content of Tab 1', key: '1' },
  { title: 'Tab 2', content: 'Content of Tab 2', key: '2' },
]

export default {
  data() {
    return {
      dateOptions: [
        {
          label: this.$t('home.all'),
          value: -1,
        },
        {
          label: this.$t('home.one_month'),
          value: `${moment().add(-1, 'month').format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD')}`,
        },
        {
          label: this.$t('home.three_month'),
          value: `${moment().add(-3, 'month').format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD')}`,
        },
        {
          label: this.$t('home.three_month'),
          value: `${moment().add(-1, 'year').format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD')}`,
        },
      ],
      dataValue: -1,
      columnsB,
      dataB,
      radioGroup: 'a',
      mockData: [],
      targetKeys: [],
      comment: [
        {
          actions: ['Reply to'],
          author: 'Han Solo',
          avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
          content:
            'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
          datetime: moment().subtract(1, 'days'),
        },
        {
          actions: ['Reply to'],
          author: 'Han Solo',
          avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
          content:
            'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
          datetime: moment().subtract(2, 'days'),
        },
      ],
      moment,
      activeKey: panes[0].key,
      panes,
      newTabIndex: 0,
      mode: 'top',
      data,
      columns,
      visible: false,
      modalVisible: false,
      plainOptions: ['Apple', 'Pear', 'Orange'],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },

      uploadValue: [
        {
          uid: 'sfadasaff=3423423=4234', // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
          name: 'ssssss.png', // 文件名
          status: 'done', // 状态有：uploading done error removed
          percent: 100,
        },
        {
          uid: 'sssssda22299239123', // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
          name: 'aaaaaaaa.png', // 文件名
          status: 'done', // 状态有：uploading done error removed
          percent: 100,
        },
      ],
      uploadValueB: [
        {
          uid: 'sfadasaff=3423423=4234', // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
          name: 'ssssss.png', // 文件名
          status: 'done', // 状态有：uploading done error removed
          percent: 100,
        },
        {
          uid: 'sssssda22299239123', // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
          name: 'aaaaaaaa.png', // 文件名
          status: 'done', // 状态有：uploading done error removed
          percent: 100,
        },
      ],
      uploadValueC: [
        {
          uid: 'sfadasaff=3423423=4234', // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
          name: 'ssssss.png', // 文件名
          status: 'done', // 状态有：uploading done error removed
          percent: 100,
        },
        {
          uid: 'sssssda22299239123', // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
          name: 'aaaaaaaa.png', // 文件名
          status: 'done', // 状态有：uploading done error removed
          percent: 100,
        },
      ],
    }
  },
  components: {
    NeoTips,
    NeoTag,
    NeoUploadDragger,
    NeoNoScrollWrap,
    NeoUploadButton,
    NeoBreadcrumb,
  },
  methods: {
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: 'Notification Title',
        description:
          'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      })
    },
    showDrawer() {
      this.visible = true
    },
    onClose() {
      this.visible = false
    },
    success() {
      this.$message.success('This is a success message')
    },
    error() {
      this.$message.error('This is an error message')
    },
    warning() {
      this.$message.warning('This is a warning message')
    },
    callback(key) {
      console.log(key)
    },
    onEdit(targetKey, action) {
      this[action](targetKey)
    },
    add() {
      const panes = this.panes
      const activeKey = `newTab${this.newTabIndex++}`
      panes.push({
        title: `New Tab ${activeKey}`,
        content: `Content of new Tab ${activeKey}`,
        key: activeKey,
      })
      this.panes = panes
      this.activeKey = activeKey
    },
    remove(targetKey) {
      let activeKey = this.activeKey
      let lastIndex
      this.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1
        }
      })
      const panes = this.panes.filter((pane) => pane.key !== targetKey)
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].key
        } else {
          activeKey = panes[0].key
        }
      }
      this.panes = panes
      this.activeKey = activeKey
    },
    getMock() {
      const targetKeys = []
      const mockData = []
      for (let i = 0; i < 20; i++) {
        const data = {
          key: i.toString(),
          title: `content${i + 1}`,
          description: `description of content${i + 1}`,
          chosen: Math.random() * 2 > 1,
        }
        if (data.chosen) {
          targetKeys.push(data.key)
        }
        mockData.push(data)
      }
      this.mockData = mockData
      this.targetKeys = targetKeys
    },
    filterOption(inputValue, option) {
      return option.description.indexOf(inputValue) > -1
    },
    handleChange(targetKeys, direction, moveKeys) {
      console.log(targetKeys, direction, moveKeys)
      this.targetKeys = targetKeys
    },
    handleSearch(dir, value) {
      console.log('search:', dir, value)
    },
  },
  mounted() {
    this.getMock()
  },
}
</script>
